

//达成条件选项数据
export const reachtypeArr = [
 
  { name: "签到", id: 0 },
  { name: "连续签到", id: 1 },
  { name: "发文章", id: 2 },
  { name: "评论文章", id: 3 },
  { name: "转发文章", id: 4 },
  { name: "收藏文章", id: 5 },
  { name: "转发企业", id: 6 },
  { name: "收藏企业", id: 7 },
  { name: "发资源", id: 8 },
  { name: "转发资源", id: 9 },
  { name: "收藏资源", id: 10 },
  { name: "参加活动", id: 11 },
  { name: "收藏活动", id: 12 },
  { name: "转发活动", id: 13 },
  { name: "社区发帖", id: 14 },
  { name: "社区评论", id: 15 },
  { name: "创建话题", id: 16 },
  { name: "购买商品", id: 17 },
  { name: "成为会员", id: 18 },
  { name: "粉丝数量", id: 19 },
  { name: "评论数量", id: 20 },
  { name: "单条帖子评论数", id: 21 },
];

//推送渠道选项数据
export const msgTypeArr = [
  { name: "全部", id: -1 },
  { name: "弹窗", id: 0 },
  { name: "系统消息", id: 1 },
  { name: "弹窗、系统消息", id: 2 },
];

//领取人数选项数据
export const usersCountArray = [
  { name: "全部", id: -1 },
  { name: "1~20", id: 0 },
  { name: "21~100", id: 1 },
  { name: "101~200", id: 2 },
  { name: "200以上", id: 3 },
];
export const  statusArray  =[

  { name: "关闭", id: 0 },
  { name: "开启", id: 1 },
]
<template>
  <div class="journal-content" v-loading="loading">
    <el-form
      size="medium"
      :model="journalForm"
      :rules="rules"
      label-position="right"
      label-width="95px"
      class="small-form"
      ref="journalForm"
    >
      <el-form-item label="勋章名称：" prop="name">
        <el-input
          type="text"
          v-model="journalForm.name"
          placeholder="请输入勋章名称"
        ></el-input>
      </el-form-item>

      <el-form-item label="图标类型：" prop="icon_type">
        <el-radio
          v-model="journalForm.icon_type"
          :label="0"
          @change="() => $refs['icon'].resetField()"
          >自定义</el-radio
        >
        <el-radio
          v-model="journalForm.icon_type"
          :label="1"
          @change="() => $refs['icon'].resetField()"
          >勋章模板</el-radio
        >
        <el-popover placement="bottom" trigger="click">
          <div>
            <img
              style="width: 250px; height: 540px"
              :src="`${baseImgUrl}/badge/badge-bg.png`"
            />
          </div>
          <el-button type="text" slot="reference">查看示例</el-button>
        </el-popover>
      </el-form-item>
      <el-form-item label="图标：" prop="icon" ref="icon">
        <el-input
          v-show="false"
          type="text"
          v-model="journalForm.icon"
        ></el-input>
        <template v-if="!journalForm.icon_type">
          <ImageItem v-model="journalForm.icon" :width="100" :height="100" />

          <div class="info"><i class="el-icon-info"></i>建议200px*200px</div>
        </template>
        <div v-else>
          <div>
            <el-button @click="badgetemplate = true">
              {{ journalForm.icon ? '修改模板' : '选择模板' }}
            </el-button>
          </div>
          <div>
            <el-image
              fit="cover"
              :preview-src-list="[journalForm.icon]"
              style="width: 100px; height: 100px"
              v-show="journalForm.icon"
              :src="journalForm.icon"
            >
            </el-image>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="勋章类型：" prop="type">
        <el-radio v-model="journalForm.type" @change="typeChange" :label="0"
          >成就勋章</el-radio
        >
        <el-radio v-model="journalForm.type" @change="typeChange" :label="1"
          >纪念勋章</el-radio
        >
        <el-radio v-model="journalForm.type" @change="typeChange" :label="2"
          >积分勋章</el-radio
        >
      </el-form-item>
      <el-form-item
        v-if="journalForm.type !== 2"
        label="达成条件："
        clearable
        ref="reach_type"
        prop="reach_type"
      >
        <el-select
          v-model="journalForm.reach_type"
          @change="() => $refs['reach_limit'].resetField()"
          placeholder="请选择达成条件"
          :loading="!reachtypeArr.length"
        >
          <template
            v-if="reachtypeArr.find((el) => journalForm.type === el.type)"
          >
            <el-option
              :key="op.id"
              :label="op.name"
              :value="op.type"
              v-for="op in reachtypeArr.find(
                (el) => journalForm.type === el.type
              ).list"
            ></el-option>
          </template>
        </el-select>
      </el-form-item>
      <el-form-item
        ref="reach_limit"
        :label="
          journalForm.type === 2
            ? '积分兑换数：'
            : journalForm.reach_type === 18
            ? '天数：'
            : journalForm.reach_type === 19
            ? '人数'
            : currentHead
            ? currentHead
            : '次数：'
        "
        prop="reach_limit"
      >
        <el-input
          type="text"
          v-model="journalForm.reach_limit"
          placeholder="请输入"
        ></el-input>
        <span v-if="journalForm.type === 2"> 积分 </span>
        <span v-else-if="journalForm.reach_type === 19"> 人 </span>
        <span v-else-if="journalForm.reach_type === 18"> 天</span>
        <span v-else-if="currentFoot"> {{ currentFoot }}</span>
        <span v-else> 次 </span>
      </el-form-item>

      <el-form-item label="勋章状态：" prop="status">
        <el-radio v-model="journalForm.status" :label="1">开启</el-radio>
        <el-radio v-model="journalForm.status" :label="0">关闭</el-radio>
      </el-form-item>
      <el-form-item label="消息推送：" prop="window_msg">
        <el-checkbox
          v-model="journalForm.window_msg"
          :true-label="1"
          :false-label="0"
          >弹窗</el-checkbox
        >
        <el-checkbox
          v-model="journalForm.system_msg"
          :true-label="1"
          :false-label="0"
          >系统消息</el-checkbox
        >
      </el-form-item>
      <el-form-item label="勋章描述：" prop="remarks">
        <el-input
          class="word-limit-form"
          type="textarea"
          v-model="journalForm.remarks"
          placeholder="请输入勋章描述"
          maxlength="20"
          show-word-limit
        >
        </el-input>
      </el-form-item>
    </el-form>
    <FixedActionBar>
      <el-button
        type="primary"
        size="medium"
        @click="formSubmit('journalForm')"
        :loading="saveLoading || fileUploading"
        >保存
      </el-button>
      <el-button size="medium" @click="cancelConfig">取消</el-button>
    </FixedActionBar>
    <BadgetemplateDialog
      v-model="badgetemplate"
      :icon="journalForm.icon"
      @setIcon="(v) => (journalForm.icon = v)"
    />
  </div>
</template>

<script>
import { badgeDetail, badgeSave } from '../api/index'
import { reachtypeArr, statusArray } from '../data/default'
import ImageWall from '../../common/components/MediaWall.vue'
import ImageItem from '@/modules/common/components/ImageItem'
import UploadFileButton from '../components/UploadFileButton.vue'
import FixedActionBar from '@/base/layout/FixedActionBar'
import BadgetemplateDialog from '../components/BadgetemplateDialog.vue'
import { getReachTypes } from '../api'
import { isFunction } from 'lodash'
export default {
  data() {
    return {
      baseImgUrl: '',
      badgetemplate: false,
      journalId: this.$route.params.id || 0,
      journalForm: {
        name: '',
        type: 0,
        icon: '',
        icon_type: 0,
        reach_type: '',
        status: 1,
        reach_limit: '',
        remarks: '',
        window_msg: 0,
        system_msg: 0,
      },
      reachtypeArr: [],

      rules: {
        name: [{ required: true, message: '请输入勋章名称', trigger: 'blur' }],
        type: [{ required: true, message: '请选着图标类型', trigger: 'blur' }],
        icon: [{ required: true, message: '请选择图标', trigger: 'change' }],
        icon_type: [
          { required: true, message: '请选择勋章类型', trigger: 'change' },
        ],
        reach_type: [
          { required: true, message: '请选择达成条件', trigger: 'change' },
        ],
        status: [{ required: true, message: '', trigger: 'change' }],
        window_msg: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (this.journalForm.window_msg || this.journalForm.system_msg) {
                callback()
              } else {
                callback(new Error('请选择消息推送方式'))
              }
            },
            trigger: 'blur',
          },
        ],
        reach_limit: [{ required: true, message: '请输入', trigger: 'blur' }],
        remarks: [
          { required: true, message: '请输入勋章描述', trigger: 'blur' },
        ],
      },
      fileUploading: false,
      loading: false,
      saveLoading: false,
    }
  },
  methods: {
    typeChange() {
      this.$nextTick(() => {
        this.$refs['journalForm'].fields.forEach((el) => {
          if (el.prop == 'reach_type') {
            // v-if后好像失效了
            el.resetField()
            this.journalForm.reach_type = ''
          } else {
            // 这是干嘛用的？
            // this.journalForm.reach_type = 22
          }
        })

        this.$refs['reach_limit'].resetField()
      })
    },
    // 获取勋章详情
    getDadgeDetail(id) {
      this.loading = true
      badgeDetail({ id })
        .then((res) => {
          const { data } = res
          // 为积分勋章时把达成条件 改为所需积分
          if (data.type == 2) {
            data.reach_limit = data.integral
          }
          this.journalForm = data
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
        })
    },
    // 保存
    formSubmit(keyWords) {
      this.$refs[keyWords].validate((valid) => {
        if (valid) {
          this.loading = true
          this.saveLoading = true
          const data = JSON.parse(
            JSON.stringify({ ...this.journalForm, id: this.journalId })
          )
          // 为积分勋章时把达成条件 改为所需积分
          if (data.type == 2) {
            data.integral = data.reach_limit
          }
          badgeSave(data)
            .then((res) => {
              this.$message.success('保存成功')
              this.timer = setTimeout(() => {
                this.loading = false
                this.saveLoading = false
                this.$router.push({ name: 'BadgeListIndex' })
              }, 1500)
            })
            .catch((err) => {
              this.loading = false
              this.saveLoading = false
            })
        } else {
          return false
        }
      })
    },
    // 取消
    cancelConfig() {
      this.$router.push({ name: 'BadgeListIndex' })
    },
    getTypes() {
      getReachTypes()
        .then(({ data, msg }) => {
          this.reachtypeArr = data.reach_types
        })
        .catch(() => {})
    },
  },
  computed: {
    currentFoot() {
      // 默认
      if (!this.reachtypeArr[this.journalForm.type]) {
        return '次'
      }
      let current = {}
      this.reachtypeArr[this.journalForm.type].list.forEach((item) => {
        if (item.type === this.journalForm.reach_type) {
          current = item.task_text
        }
      })
      return current.foot
    },
    currentHead() {
      // 默认
      if (!this.reachtypeArr[this.journalForm.type]) {
        return '次数：'
      }

      let current = ''
      this.reachtypeArr[this.journalForm.type].list.forEach((item) => {
        if (item.type === this.journalForm.reach_type) {
          current = item.task_text
        }
      })
      console.log(current)
      if (current) {
        return current.head + '：'
      } else {
        return '次数：'
      }
    },
  },
  created() {
    if (this.journalId) {
      this.getDadgeDetail(this.journalId)
    }
    this.baseImgUrl = window.serverConfig.VUE_APP_IMG_URL

    this.getTypes()
  },
  components: {
    ImageItem,
    ImageWall,
    UploadFileButton,
    FixedActionBar,
    BadgetemplateDialog,
  },
}
</script>

<style lang="scss" scoped>
.journal-content {
  button {
    & + button {
      margin-left: 20px;
    }
  }

  ::v-deep.el-date-editor.el-input {
    width: 295px;
  }

  .footer-btn {
    padding: 30px 40px 10px;
  }

  .form-tips {
    color: #c0c4cc;
  }

  .img-tips {
    margin-top: 12px;
    font-size: 12px;
    color: #c0c4cc;
    line-height: 15px;
  }
}

.info {
  margin-top: 15px;
  text-align: center;
  color: #999;
  width: 130px;
}
</style>

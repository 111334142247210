<template>
    <el-dialog :visible.sync="openDialog" v-loading="loading" @open="init"  height="400px" width="700px" title="勋章模板">
        <el-radio-group v-model="radio">
            <el-radio v-for="(t, i) in list" :key="i" :label="t" border style="width: 140px; height:130px;">
                <img style="width: 100px; height: 100px; border-radius: 50%;" :src="t" alt="">
            </el-radio>
        </el-radio-group><span slot="footer" class="dialog-footer">
            <el-button @click="openDialog = false">取 消</el-button>
            <el-button type="primary" @click="OK">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>
import { templates } from '../api/index'
export default {
    name: 'BadgetemplateDialog',
    model: {
        prop: "open",
        event: "closeDialog",
    },
    props: {
        open: {
            type: Boolean,
            default: false,
        },
        icon: {
            type: String
        }
    },
    data() {
        return { radio: '1', loading: false, list: [] }
    },

    methods: {
        OK() {
            if (!this.radio) {
                this.$message.error('请选择勋章模板')
                return
            }
            this.$emit("setIcon", this.radio);
            this.openDialog = false
        },
        init() {
            this.loading = false
            this.radio = this.icon
            templates().then(res => {
                console.log(res);
                this.list = res.data.templates
            })

        }
    },
    computed: {
        openDialog: {
            get() {
                return this.open;
            },
            set(val) {
                this.$emit("closeDialog", val);
            },
        },
    }

}
</script>

<style lang="scss" scoped>
::v-deep .el-radio__inner {
    display: none;
}

::v-deep .el-radio {
    margin: 10px !important;

}
</style>